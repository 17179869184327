
export default {
    async asyncData({ req, params, ssrContext, route }) {
        let host = null
        let userIdentifier = null
        let customPayload = null
        if (process.server) {
            host = req.headers.host
            userIdentifier = ssrContext.nuxt.userIdentifier
            customPayload = ssrContext.nuxt.customPayload
            console.log("req.url", req.url)
            console.log("path", JSON.stringify(route, null, 2))
            return { host, userIdentifier, customPayload, path: req.url }
        } else {
            host = window.location.host
            userIdentifier = window.__NUXT__.userIdentifier
            customPayload = window.__NUXT__.customPayload
            console.log('userIdentifier', userIdentifier)
            console.log('host', host)
            return { host, userIdentifier, customPayload, path: window.location }
        }
    },
    data() {
        return {
            host: null
        }
    }
}
